import { useState, useEffect, useMemo } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { useMutation, gql } from '@apollo/client'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useIonToast } from '@ionic/react'
import Toast from '../utils/Toast'
import DatePickerField from './DatePickerField'
import ProgressBar from './ProgressBar'
import { ActionHeader } from './Header'
import { useLogFeatureInfo, useLogFeatureError } from '../utils/session'
import './FormSpray.css'

const FOUR_HOURS_MS = 4*60*60*1000
const CREATE_SPRAY_LOG = gql`
mutation spray_log_create($tower_id: ID!, $reading_stream_id: ID!, $start_date: String!, $finish_date: String!, $note: String) {
	spray_log_create(
		tower_id: $tower_id
		reading_stream_id: $reading_stream_id
		start_date: $start_date
		finish_date: $finish_date
		note: $note
	) {
		message
	}
}`

const FormSpray = ({ towerId, towerName, readingId, onCancel, onConfirm }:{ towerId:number, towerName:string, readingId:number, onCancel:Function, onConfirm:Function }) => {
	const [createSprayLog, { error, loading, called }] = useMutation(CREATE_SPRAY_LOG)
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date(Date.now()+FOUR_HOURS_MS))
	const [startDateError, setStartDateError] = useState(false)
	const [endDateError, setEndDateError] = useState(false)
	const [note, setNote] = useState<string | null>(null)
	const ionToast = useIonToast()
	const toast = useMemo(() => new Toast(...ionToast, { duration:4000 }), [ionToast])
	const logFeat = useLogFeatureInfo()
	const logFeatError = useLogFeatureError()

	useEffect(() => {
		return () => {
			toast.dismiss()
		}
	})

	useEffect(() => {
		if (!loading && called) {
			if (error) {
				console.error(error)
				toast.show('Failed to create spray log. Please contact support for further help.', { error:true, closeText:'close' })
			} else {
				toast.show('Spray log successfully registered')
				onConfirm()
			}
		} 
	}, [loading, called, toast, onConfirm, error])

	const onSave = async () => {
		if (startDate > endDate) {
			setStartDateError(true)
			setEndDateError(true)
			toast.show('The finish date must be strictly greater than the start date', { error:true, closeText:'close' })
			return
		}
		
		const variables = {
			tower_id: towerId,
			reading_stream_id: readingId,
			start_date: startDate.toISOString(),
			finish_date: endDate.toISOString(),
			note
		}

		createSprayLog({ 
			variables,
			onCompleted() {
				logFeat({ title:'spray', content:towerName })
			},
			onError(error) {
				logFeatError({ title:'spray', content:JSON.stringify({ towerId, towerName, error:error.message }) })
			}
		})
	}

	const onStartDateChange = (val:any) => {
		if (startDateError)
			setStartDateError(false)
		setStartDate(val)
		if (val > endDate)
			setEndDate(val)	
	}
	const onEndDateChange = (val:any) => {
		if (endDateError)
			setEndDateError(false)
		setEndDate(val)
		if (val < startDate)
			setStartDate(val)
	}

	return (<IonPage>
		{loading && <ProgressBar/>}
		<ActionHeader actionName="Save" onCancel={onCancel} onConfirm={onSave}/>
		<IonContent className="ion-padding">
			<Typography className="spray-instruction">
				Enter a start and finish time, then press the "Save" button to record your spray event:
			</Typography>
			<Box className="spray-input-form">
				<DatePickerField 
					label="Start date" 
					labelTime="Start time" 
					required 
					date={startDate} 
					time={true}
					onChange={onStartDateChange} 
					error={startDateError} 
					disabled={loading}/>
				<DatePickerField 
					label="Finish date" 
					labelTime="Finish time" 
					required 
					date={endDate} 
					minDate={startDate}
					time={true}
					onChange={onEndDateChange} 
					error={endDateError} 
					disabled={loading}/>
				<TextField
					id="outlined-multiline-static"
					className="spray-textarea"
					label="Optional note"
					onChange={(e:any) => setNote(e.target.value)} 
					multiline
					rows={6}
					disabled={loading}
					placeholder="Add an optional note"
				/>
			</Box>
		</IonContent>
	</IonPage>)
}

export default FormSpray