import { useEffect, useState } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { formatDate } from 'puffy-core/date'
import { useQuery, gql } from '@apollo/client'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import parse from 'html-react-parser'
import ProgressBar from '../components/ProgressBar'
import { TitleHeader } from '../components/Header'
import { getSessionUser } from '../auth'
import { LOGIN_PATH, NOTIFICATION_PATH } from '../path'

const DATE_FORMAT = { format:'dd/MM/yyyy HH:mm' }

const GET_NOTIFICATION = gql`
query get_notifications($id:ID!) {
	notifications(
		where: {
			id: $id
		}
	) {
		data {
			create_date
			data {
				id
				title
				sub_title
				type
				message
				body
				link_url
			}
		}
	}
}`

const Container = styled.div`
	padding: 26px;
`

const NotificationMessage = ({ ...props }) => {
	const location = useLocation<any>()
	const id = ((location.pathname||'').match(/\/notifications\/message\/([0-9]*?)$/)||[])[1]
	const notificationOp = useQuery(GET_NOTIFICATION, {
		variables: {
			id: id||0
		}
	})
	const notification = (notificationOp.data?.notifications?.data||[])[0]
	// const [data, setData] = useState<any>({})
	// const [data, setData] = useState<any>({})

	const head = <Helmet>
		<title>Wand - {notification?.data?.title||'Message'}</title>
	</Helmet>

	const history = useHistory()

	// useEffect(() => {
	// 	if (!notificationOp.loading && notificationOp.called) {
	// 		const d = (notificationOp.data?.notifications?.data||[])[0]?.data
	// 		if (d)
	// 			setData(d)
	// 	}
	// }, [notificationOp])

	// Manages redirection to login page if user is not authenticated
	const [redirect, setRedirect] = useState<boolean | null>(null)
	useEffect(() => getSessionUser().then((resp: any) => setRedirect(resp && resp[0] ? true : false)), [])
	if (redirect === null)
		return null

	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<IonPage>
			{notificationOp.loading && <ProgressBar/>}
			{head}
			<TitleHeader 
				title={<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 'calc(100vw - 90px)' }}>{notification?.data?.title}</div>} 
				subTitle={notification?.create_date ? formatDate(new Date(notification?.create_date), DATE_FORMAT) : ''} 
				onBack={() => history.push(NOTIFICATION_PATH)} />
			<IonContent fullscreen>
				{!notificationOp.loading && <Container>
					{parse(notification?.data?.body || notification?.data?.message || '')}
				</Container>}
			</IonContent>
		</IonPage>
	)
}

export default NotificationMessage