import { useState, useEffect, useMemo, useRef } from 'react'
import { useMutation, gql } from '@apollo/client'
import { IonContent, IonPage, useIonToast } from '@ionic/react'
import Toast from '../utils/Toast'
import { formatDate } from 'puffy-core/date'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import DatePickerField from './DatePickerField'
import ProgressBar from './ProgressBar'
import { useLogFeatureInfo, useLogFeatureError } from '../utils/session'
import { ActionHeader } from './Header'

const SELECT_ALL_LABEL = 'Select all'
const SEVEN_DAYS = 7*24*60*60*1000

const CREATE_REPORT_DATA = gql`
mutation get_report($tower_id:ID!, $from:String!, $to:String!, $fields:[ReportFieldEnum]) {
	tower_reading_report_create(
		where: { 
			tower:{ id:$tower_id } 
			date:{
				from:$from
				to:$to
			}
		}
		timezone: LOCAL,
		fields:$fields
		format:csv
	) {
		message
		fileUrl
	}
}`

const FIELDS:any = Object.values({
	hazard_status: {
		id: 'hazard',
		label: 'Hazardous Inversion'
	},
	temp_inversion: {
		id: 'tempdiff_h10_h1_avg',
		label: 'Inversion (Vert Temp Diff)'
	},
	ws_2: {
		id: 'ws_2',
		label: 'Wind Speed (2m)'
	},
	windgust_2: {
		id: 'windgust_2',
		label: 'Max Wind Gust (2m)'
	},
	ws_10: {
		id: 'ws_10',
		label: 'Wind Speed (10m)'
	},
	delta_t: {
		id: 'deltat_1',
		label: 'Delta T'
	},
	temp: {
		id: 'temp_1',
		label: 'Temperature'
	},
	solr: {
		id: 'solardown_10min',
		label: 'Solar Radiation'
	},
	rain: {
		id: 'rain_acc',
		label: 'Rainfall from 9 am'
	},
	rain_prev_24h: {
		id: 'rain_prev_24h',
		label: 'Rainfall 24 hrs to 9 am'
	},
	humidity: {
		id: 'humidity',
		label: 'Relative Humidity'
	}
}).map((val:any) => {
	const { id, label } = val||{}
	return label ? { id, label } : null
}).filter((x:any) => x)

const DatePickerBox = styled.div`
	margin: 26px 8px;
	display: flex;
	flex-direction: column;
	
	& > p, > div {
		margin-bottom: 15px;
	}
` 

const RowItem = styled.div`
	display: flex;
	gap: 40px;
	width: 330px;
	margin-left: 10px;
	margin-right: 10px;

	& .label {
		width: 150px;
		text-align: left;
	}
`

const ModalTitle = styled.div`
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	justify-content: center;

	@media (max-width: 400px) {
		font-size: 14px;
	}

	@media (max-width: 768px) {
		width: calc(100vw - 223px);
	}
`

const Row = (input:any) => {
	const { id, selectall, onClick, value, disabled } = input
	const name = selectall ? SELECT_ALL_LABEL : input.name
	const [checked, setChecked] = useState<boolean>(true)
	const [indeterminate, setIndeterminate] = useState(false)

	useEffect(() => {
		if (value === null) {
			if (selectall) {
				setIndeterminate(true)
				setChecked(false)
			}
		} else {
			setIndeterminate(false)
			setChecked(value)
		}
	}, [value, selectall])

	const props:any = {}
	if (onClick)
		props.onClick = (e:any) => {
			if (selectall && value === null) {
				setIndeterminate(false)
				setChecked(false)
			} else
				setChecked(e.target.checked)
			onClick(e)
		}

	return (
	<RowItem>	
		<FormControlLabel control={<Checkbox 
			id={id} 
			checked={checked} 
			disabled={disabled}
			indeterminate={indeterminate}
			{...props} 
			className="ic-field"/>} label={!selectall ? name : (<Box sx={{ fontSize:'0.92rem', opacity:0.7 }}>{name}</Box>)} />
	</RowItem>)
}

interface FormDownloadHistoryProps {
	towerId: any,
	towerName: string,
	onCancel: Function, 
	onDownloaded: Function
}

export default function FormDownloadHistory(props: FormDownloadHistoryProps) {
	const { towerId, towerName, onCancel, onDownloaded } = props
	const now = new Date()
	const sevenDaysAgo = new Date(Date.now() - SEVEN_DAYS)

	const [historyReportFilter, setHistoryReportFilter] = useState<any>(null)
	const [selectAll, setSelectAll] = useState<boolean | null>(true)
	const [startDate, setStartDate] = useState(sevenDaysAgo)
	const [endDate, setEndDate] = useState(now)
	const downloadRef = useRef<HTMLAnchorElement>(null)
	const [lastFileDownloaded, setLastFileDownloaded] = useState('')
	
	const [createHistoryReport, { loading }] = useMutation(CREATE_REPORT_DATA)
	const logFeat = useLogFeatureInfo()
	const logFeatError = useLogFeatureError()

	const ionToast = useIonToast()
	const toast = useMemo(() => new Toast(...ionToast, { duration:4000 }), [ionToast])

	const fieldSelected = (e:any) => {
		const _checkboxes = document.querySelectorAll('.ic-field input')||[]
		const checkboxes = []
		for (let i=0;i<_checkboxes.length;i++) {
			if (_checkboxes[i].id)
				checkboxes.push(_checkboxes[i])
		}
		
		if (checkboxes.every((c:any) => c.checked))
			setSelectAll(true)
		else if (checkboxes.every((c:any) => !c.checked))
			setSelectAll(false)
		else if (checkboxes.some((c:any) => c.checked)) 
			setSelectAll(null)
		emitChange()
	}

	const emitChange = useMemo(() => (allFieldsSelected?:boolean|null, sDate?:Date, eDate?:Date) => {
		const _checkboxes = document.querySelectorAll('.ic-field input')||[]
		const fields = []
		if (allFieldsSelected === null || allFieldsSelected === undefined || allFieldsSelected === true)
			for (let i=0;i<_checkboxes.length;i++) {
				const check:any = _checkboxes[i]
				if (check.id && (allFieldsSelected === true || check.checked))
					fields.push(check.id)
			}

		const data = {
			from: (sDate||startDate).toISOString(),
			to: (eDate||endDate).toISOString(),
			fields
		}

		setHistoryReportFilter(data)

	}, [setHistoryReportFilter, startDate, endDate])


	useEffect(() => {
		emitChange()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const onStartDateChange = (date:Date) => {
		const sDate = date
		setStartDate(date)
		let eDate = endDate
		if (date > endDate) {
			eDate = date
			setEndDate(date)
		}

		emitChange(null, sDate, eDate)
	}
	const onEndDateChange = (date:Date) => {
		const eDate = date
		setEndDate(date)
		let sDate = startDate
		if (date < startDate) {
			sDate = date
			setStartDate(date)
		}
		
		emitChange(null, sDate, eDate)
	}
	const onSelectAll = () => {
		setSelectAll(!selectAll)
		emitChange(!selectAll)
	}

	const onSave = () => {
		const { from, to, fields } = historyReportFilter || {}
		if (!from) {
			toast.show('The \'From\' date is required', { error:true, closeText:'close' })
			return
		}
		if (!to) {
			toast.show('The \'To\' date is required', { error:true, closeText:'close' })
			return
		}
		if (!fields || !fields.length) {
			toast.show('At least one field must be selected', { error:true, closeText:'close' })
			return
		}
		toast.dismiss()

		createHistoryReport({
			variables:{
				tower_id: towerId,
				from, 
				to, 
				fields
			},
			onCompleted(data:any) {
				logFeat({ title:'download', content:towerName })
				const { fileUrl, message } = data?.tower_reading_report_create
				if (fileUrl) {
					const from = formatDate(new Date(historyReportFilter?.from), { format:'yyyyMMddHHmm' })
					const to = formatDate(new Date(historyReportFilter?.to), { format:'yyyyMMddHHmm' })
					const n = (towerName||'').toLowerCase().trim().replace(/[^a-z0-9]/g,'')
					const nbr = Math.round((Date.now() - 1661990400000)/1000)
					const fileName = `${from}-${to}-${n}-${nbr}.csv`
					const ref:any = downloadRef.current
					ref.download = fileName
					ref.href = fileUrl
					if (fileUrl !== lastFileDownloaded) {
						ref.click()
						setLastFileDownloaded(fileUrl)
					}
					onDownloaded()
				} else {
					const errMsg = `Failed to download tower's history.${message ? ` ${message}` : ''}`
					toast.show(errMsg, { error:true, closeText:'close' })
					console.error(`${errMsg}. The API response is missing the required 'fileUrl' value. Response: ${JSON.stringify(data||{})}`)
				}
			},
			onError(error:any) {
				logFeatError({ title:'download', content:JSON.stringify({ towerId, towerName, error:error.message }) })
				console.error(error)
				toast.show(`Failed to download tower's history`, { error:true, closeText:'close' })
			}
		})
	}

	return (<IonPage>
		{loading && <ProgressBar/>}
		<ActionHeader 
			actionName="Download" 
			title={<ModalTitle>{towerName}</ModalTitle>} 
			onCancel={onCancel} 
			onConfirm={onSave}/>
		<IonContent className="ion-padding">
			<DatePickerBox>
				<Typography sx={{ width:'330px' }} color={loading ? 'text.disabled' : ''}>
					Select a date range:
				</Typography>
				<DatePickerField 
					label="From"
					date={startDate} 
					onChange={onStartDateChange} 
					disabled={loading}
					/>
				<DatePickerField 
					label="To"
					date={endDate} 
					minDate={startDate}
					onChange={onEndDateChange} 
					disabled={loading}
					/>
			</DatePickerBox>
			<Divider/>
			<div>
				<FormGroup>
						<Typography sx={{ marginBottom:'12px', marginLeft:'10px', marginTop: '20px' }} color={loading ? 'text.disabled' : ''}>
							Select the fields that must be included in the historical data:
						</Typography>
						<Row selectall={true} value={selectAll} onClick={onSelectAll} disabled={loading} />
						<Divider/>
						{FIELDS.map((p:any, key:number) => {
							return <Row id={p.id} name={p.label} value={selectAll} key={key} onClick={fieldSelected} disabled={loading} />
						})}
				</FormGroup>
			</div>
			<a ref={downloadRef} style={{ visibility:'hidden' }} href="#">download</a>
		</IonContent>
	</IonPage>)
}





