export const HOME_PATH = '/'
export const CONFIRM_CODE_PATH = '/confirmcode'
export const FORGOT_PWD_PATH = '/forgotpwd'
export const LOGIN_PATH = '/login'
export const NOTIFICATION_MESSAGE_PATH = '/notifications/message'
export const NOTIFICATION_PATH = '/notifications'
export const NOTIFICATION_TYPES_PATH = '/settings/notifications'
export const PRIVACY_POLICY_PATH = '/privacy-policy'
export const PROFILE_PATH = '/settings/profile'
export const RESET_PWD_PATH = '/resetpwd'
export const SEARCH_PATH = '/search'
export const SETTINGS_PATH = '/settings'
export const SIGNUP_PATH = '/signup'
export const SPRAY_LOG_PATH = '/settings/spraylog'
export const TERMS_PATH = '/terms'