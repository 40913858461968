// import { useState } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import styled from 'styled-components'
import { ActionHeader } from './Header'
import Calendar from './Calendar'

const ModalTitle = styled.div`
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	justify-content: center;

	@media (max-width: 400px) {
		font-size: 14px;
	}

	@media (max-width: 768px) {
		width: calc(100vw - 223px);
	}
`

export default function FormDatePicker(props:any) {
	const { start, end, onCancel, onSelected } = props
	let range:any = [start, end]

	const style = {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		marginTop: '20px'
	}

	const onConfirm = () => {
		onSelected(range)
	}

	return (<IonPage>
		<ActionHeader 
			actionName="Select" 
			title={<ModalTitle>{"Custom date range"}</ModalTitle>} 
			onCancel={onCancel} 
			onConfirm={onConfirm}/>
		<IonContent className="ion-padding">
			<div style={style}>
				<Calendar range={[start, end]} onRangeSelected={(input:any) => range = input}/>
			</div>
		</IonContent>
	</IonPage>)
}





