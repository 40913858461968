import { IonHeader } from '@ionic/react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ArrowBack from '@mui/icons-material/ArrowBack'
import './Header.css'

export const Header = ({ left, middle, right, height }:{ left?:any, middle?:any, right?:any, height?:any }) => {
	const middleClass = middle 
		? (left && right ? 'page-header-middle-center' : 'page-header-middle-left')
		: ''
	return (
	<IonHeader className="page-header" style={{ '--page-header-height': height||'56px' }}>
		<div className="page-header-container">
			<div className="page-header-item page-header-left">
				{left||null}
			</div>
			<div className={`page-header-item ${middleClass}`}>
				{middle||null}
			</div>
			<div className="page-header-item page-header-right">
				{right||null}
			</div>
		</div>
	</IonHeader>)
}

export const TitleHeader = ({ title, subTitle, onBack }:{ title?:any, subTitle?:string, onBack:Function }) => {
	return (<Header 
		height={title && subTitle ? '70px' : null}
		left={<IconButton sx={{ p: '10px' }} aria-label="back" onClick={() => onBack()}>
			<ArrowBack/>
		</IconButton>}
		middle={
			<div className="page-header-title">
				{title && <Typography variant="h6" className="title-item">{title}</Typography>}
				{subTitle && <Typography variant="h6"  className="title-item subtitle">{subTitle}</Typography>}
			</div>
		}/>)
}

export const ActionHeader = ({ title, actionName, onCancel, onConfirm }:{ title?:any, actionName:string, onCancel:Function, onConfirm:Function }) => {
	return (<Header 
		left={<Button variant="text" sx={{ paddingLeft:'15px' }} onClick={() => onCancel()}>Cancel</Button>}
		middle={title && <Typography variant="h6" sx={{ padding:'0px 20px 0px 20px' }}>{title}</Typography>}
		right={<Button variant="text" sx={{ paddingRight:'15px' }} onClick={() => onConfirm()}>{actionName}</Button>}/>)
}


export default Header