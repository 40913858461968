import { useEffect, useState, Fragment } from 'react'
import { useRef} from 'react'
import { useQuery, gql } from '@apollo/client'
import { IonContent, IonPage } from '@ionic/react'
import { IonModal } from '@ionic/react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { formatDate } from 'puffy-core/date'
import { getSessionUser } from '../auth'
import { LOGIN_PATH, SETTINGS_PATH} from '../path'
import { TitleHeader } from '../components/Header'
import { SPRAY_LOG_PATH } from '../path'

const DATE_FORMAT = { format:'dd/MM/yyyy HH:mm' }
const getSprayLogsGql = (includeReading?:boolean) => gql`
query get_spray_logs${includeReading ? '_with_reading' : ''} {
	spray_logs(
		sort:{
			dir:desc
		}
		limit:1000
	) {
		data{
			id
			tower {
				id
				name
				tz
			}
			start_date
			finish_date
			create_date
			note
			${!includeReading ? '' : `
			reading {
				id
				date_utc
				deltat_1
				temp_1
				tempdiff_h10_h1_avg
				ws_2
				ws_10
				wd_10_label
				wd_cv2_label
				windgust_2
				solardown_10min
				rain_acc
				rain_prev_24h
				hazard_status
				nowcast
				humidity
			}
			`}
		}
	}
}`

const GET_SPRAY_LOGS = getSprayLogsGql()
// const GET_SPRAY_LOGS_WITH_READINGS = getSprayLogsGql(true)

const Container = styled.div`
	padding-top: 10px;
`

const LogDataContainer = styled.div`
	padding: 25px 15px 15px 15px;
`

const LogItem = styled.div`
	margin: 20px 26px 20px 26px;
	display: grid;
	grid-template-rows: 30px repeat(2, 22px);
	grid-template-columns: 1fr 80px;
	grid-template-areas:
		'tower	details'
		'date	 details'
		'logged details';

	& .tower-item {
		grid-area: tower;
		font-weight: 500;
	}

	& .date-item {
		grid-area: date;
		font-weight: 500;

		& .dash {
			margin: 0px 8px 0px 8px;
		}
	}

	& .logged-item {
		grid-area: logged;
	}

	& .details-item {
		grid-area: details;
		display: flex;
		align-items: flex-start;
	}
`

const noteToHtml = (note:any) => {
	return (note||'Empty note').replace(/\n/g,'<br/>')
}

const SprayLog = () => {
	const head = <Helmet>
		<title>Wand - Spray logs</title>
	</Helmet>
	
	const sprayLogs = useQuery(GET_SPRAY_LOGS)
	// const reading = useQuery(GET_SPRAY_LOGS_WITH_READINGS)

	const location = useLocation()
	const history = useHistory()
	const modal = useRef<HTMLIonModalElement>(null)
	const [logTitle, setLogTitle] = useState('')
	const [logSubtitle, setLogSubtitle] = useState('')
	const [selectedLog, setSelectedLog] = useState<any>(null)

	// Refetch each time we land on this page
	useEffect(() => {
		if (location.pathname == SPRAY_LOG_PATH && !sprayLogs.loading)
			sprayLogs.refetch()
	}, [location, sprayLogs])

	// Manages redirection to login page if user is not authenticated
	const [redirect, setRedirect] = useState<boolean | null>(null)
	useEffect(() => getSessionUser().then((resp: any) => setRedirect(resp && resp[0] ? true : false)), [])
	if (redirect === null)
		return null

	const showModal = (log:any) => {
		const selected = (sprayLogs.data?.spray_logs?.data||[]).find((x:any) => x.id == log?.id)
		if (selected)
			setSelectedLog(selected)
		setLogTitle(`Log for tower ${log.tower.name}`)
		setLogSubtitle(`${formatDate(new Date(log.start_date), DATE_FORMAT)} - ${formatDate(new Date(log.finish_date), DATE_FORMAT)}`)
		modal.current?.present()
	}

	const closeModal = () => {
		modal.current?.dismiss()
	}

	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<IonPage>
			{head}
			<TitleHeader title="Spray log" onBack={() => history.push(SETTINGS_PATH)} />
			<IonContent fullscreen>
				<Container> {sprayLogs.loading ? 
					<Box sx={{ display: 'flex', justifyContent:'center', paddingTop:'30px' }}>
						<CircularProgress/> 
					</Box> : !(sprayLogs.data?.spray_logs?.data||[]).length ?
					<div style={{ display:'flex', justifyContent:'center', marginTop:'50px' }}>
						<Typography color="text.secondary">No spray records saved</Typography>
					</div> : sprayLogs.data.spray_logs.data.map((l:any, idx:number) => (
					<Fragment key={l.id}>
						<LogItem>
							<Typography variant="body1" className="tower-item">{l.tower.name}</Typography>
							<Typography variant="caption" className="date-item">
								{formatDate(new Date(l.start_date), DATE_FORMAT)}
								<span className="dash">-</span>
								{formatDate(new Date(l.finish_date), DATE_FORMAT)}
							</Typography>
							<Typography variant="caption" color="text.secondary" className="logged-item">Logged on {formatDate(new Date(l.create_date), DATE_FORMAT)}</Typography>
							<Box className="details-item">
								<Button variant="text" sx={{ marginTop:'-6px' }} onClick={() => showModal(l)}>Details</Button>
							</Box>
						</LogItem>{idx-1 >= (sprayLogs.data?.spray_logs?.data||[]).length ? null : 
						<Divider/>}
					</Fragment>))}		
				</Container>
			</IonContent>
			<IonModal ref={modal}>
				<TitleHeader title={logTitle} subTitle={logSubtitle} onBack={closeModal} />
				<IonContent fullscreen style={{ '--background': 'white' }}>
					<LogDataContainer>
						<Box sx={{ display: 'flex', justifyContent:'center' }}>{sprayLogs.loading ? 
							<CircularProgress/> : selectedLog && 
							<div style={{ padding: '16px', width:'100%' }}>
								<Typography variant="h5" sx={{ paddingBottom:'15px' }}>Note</Typography>
								<div
									style={{ fontStyle: 'italic' ,color: '#5b5b5b', lineHeight: '22px' }} 
									dangerouslySetInnerHTML={{ __html:noteToHtml(selectedLog.note)}}/>
							</div>}
						</Box>
					</LogDataContainer>
				</IonContent>
			</IonModal>
		</IonPage>
	)
}


export default SprayLog