import { useState } from 'react'
import styled from 'styled-components'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const ONE_DAY_MS = 24*60*60*1000
const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const CalendarDiv = styled.div`
	font-size: 14px;
	display: grid;
	grid-template-rows: 250px;
	grid-template-columns: 270px 147px;
	grid-template-areas:
		'dates   predefined'
		'footer  footer';  

	& .noselect {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
	}

	& .calendar-footer {
		grid-area: footer;

		& .calendar-footer-container {
			margin-top: 20px;
			width: 100%;
			height: 20px;
			border-top: 1px solid #00000021;
			display: flex;
			justify-content: center;

			& div {
				margin-top: 20px;
			}
		}
	}

	& .calendar {
		grid-area: dates;
		display: grid;
		grid-template-rows: 50px 200px;
		grid-template-columns: 1fr;
		grid-template-areas:
			'selector'
			'days'; 
		border-right: 1px solid #00000021;

		& .dates-selector {
			grid-area: selector;
			display: grid;
			grid-template-columns: 45px auto 45px;

			& .date-title {
				font-size: 16px;
				font-weight: bold;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			& .arrow {
				cursor: pointer;
				display: flex;
				align-items: center;

				&.right {
					justify-content: start;
				}
				&.left {
					justify-content: end;
				}
			}
		}

		& .month-days {
			grid-area: days;
			display: flex;
			justify-content: center;

			& .day-cell {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 32px;
				height: 32px;
				-webkit-touch-callout: none; /* iOS Safari */
				-webkit-user-select: none; /* Safari */
				-khtml-user-select: none; /* Konqueror HTML */
				-moz-user-select: none; /* Old versions of Firefox */
				-ms-user-select: none; /* Internet Explorer/Edge */
				user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

				& .day-selection {
					position: absolute;
					width: 100%;
					height: 80%;
					z-index:1;
				}

				&.head, &.tail, &.selected {
					& .day-selection {
						background-color: #1976d2;	
					}
					& span {
						color: white;
					}
				}

				&.head .day-selection {
					border-radius: 30px 0px 0px 30px;
				}

				&.tail .day-selection {
					border-radius: 0px 30px 30px 0px;
				}

				&:hover:not(.notselectable) {
					& span {
						color: white;
					}
					& .day-selection {
						background-color: #1976d2;	
						border-radius: 30px;
					}
				}

				&.notselectable {
					color: gray;
				}
			}

			& .days-headers {
				color: #00000080;
				display: flex;

			}

			& .week-days-row {
				display: flex;

				& .day-cell.selectable {
					cursor: pointer;
				}
			}
		}
	}

	& .calendar-predefined {
		grid-area: predefined;
		padding: 20px;
		padding-top: 38px;

		& .header {
			color: #00000080;
			padding-bottom: 12px;
			padding-left: 0px;	
		}

		& div:not(.header) {
			cursor: pointer;
			width: 100%;
			height: 28px;
			display: flex;
			align-items: center;
			justify-content: start;
			border-radius: 4px;
			padding-left: 8px;

			&:hover {
				background-color: #f6f6f6;
			}
		}
	}
`

/**
 * Creates an array of week days (Sun(index 0) -> Sat (index 6))
 * 
 * @param	{Number}		month		e.g., 3 (March)
 * @param	{Number}		year		e.g., 2023
 * 
 * @return	{[[Number]]}	weekDays	e.g., [[null, null, null, 1 , 2, 3, 4], [5, 6, 7, 8, 9, 10, 11], ...]
 */
const getWeekDays = (month:number, year:number) => {
	let date = new Date(`${year}-${month < 10 ? `0${month}` : month}-01T00:00:00Z`)
	let weekDay = date.getDay()
	let currentMonth = date.getMonth() + 1
	const weekDays = []
	while(currentMonth == month) {
		const week = []
		for (let i=0;i<7;i++) {
			if (weekDay == i && currentMonth == month) {
				week.push({
					day: date.getDate(),
					date: date.toISOString().split('T')[0]
				})
				date = new Date(date.getTime() + ONE_DAY_MS) // add 1 day
				currentMonth = date.getMonth() + 1
				weekDay = date.getDay()
			}
			else
				week.push(null)
		}
		weekDays.push(week)
	}

	return weekDays
}

const addStyle = (weekDays:any, startDate?:string, endDate?:string, hoveredDate?:string) => {
	const selectFromStartToHover = startDate && !endDate && hoveredDate && hoveredDate > startDate
	const selectFromStartToEnd = startDate && endDate && startDate < endDate
	return weekDays.map((d?:any) => {
		const cellClasses = ['day-cell']
		const cellBackgroundClasses = []
		const isFuture = isDateFuture(d?.date)
		if (d?.day) {
			cellBackgroundClasses.push('day-selection')
			if (!isFuture)
				cellClasses.push('selectable')
			else
				cellClasses.push('notselectable')
		}
		if (startDate && !isFuture) {
			if (d?.date == startDate)
				cellClasses.push('head')
		}
		if (endDate && !isFuture) {
			if (d?.date == endDate) {
				cellClasses.push('tail')
			}
		}

		if (selectFromStartToEnd) {
			if (d?.date && startDate < d?.date && d?.date < endDate)
				cellClasses.push('selected')
		}
		else if (selectFromStartToHover && !isFuture) {
			if (d?.date && startDate < d?.date && d?.date < hoveredDate)
				cellClasses.push('selected')
		}

		return {
			...(d||{}),
			isFuture,
			cellClass: cellClasses.join(' '),
			cellBackgroundClass: cellBackgroundClasses.join(' ')
		}
	})
}

const getDateStringFromRange = (range:any, index:any) => {
	const date = (range||[])[index]
	if (date && date.toISOString)
		return date.toISOString().split('T')[0]
	else
		return ''
}

const isCurrentMonth = (year:any, month:any) => {
	const now = new Date()
	const currentYear = now.getFullYear()
	const currentMonth = now.getMonth() + 1
	return year == currentYear && month == currentMonth
}

const isDateFuture = (date:any) => date && new Date(date) > new Date()

const Calendar = ({ ...props }) => {
	const [month, setMonth] = useState(props.month||(new Date().getMonth()+1))
	const [year, setYear] = useState(props.year||new Date().getFullYear())
	const [startDate, setStartDate] = useState(getDateStringFromRange(props.range, 0))
	const [endDate, setEndDate] = useState(getDateStringFromRange(props.range, 1))
	const [hoveredDate, setHoveredDate] = useState('')

	const addMonth = (monthQuantity:number) => () => {
		const newMonth = month + monthQuantity
		const previousYear = newMonth <= 0
		const nextYear = newMonth > 12
		if (!previousYear && !nextYear)
			setMonth(newMonth)
		else if (previousYear) {
			setMonth(12)
			setYear(year-1)
		} else if (nextYear) {
			setMonth(1)
			setYear(year+1)
		}
	}

	const selectDate = (stringDate?:any) => {
		if (stringDate) {
			if (startDate && endDate) {
				setStartDate(stringDate)
				setEndDate('')
			} else if (startDate) {
				if (stringDate > startDate)
					setEndDate(stringDate)
				else
					setStartDate(stringDate)
			} else {
				setStartDate(stringDate)
				setEndDate('')
			}
		}
	}

	const onHoverDay = (day:any) => () => {
		if (startDate && !endDate && day?.date)
			setHoveredDate(day?.date)
		else if (hoveredDate)
			setHoveredDate('')
	}

	const selectLastDays = (lastDays:number) => () => {
		setStartDate(new Date(Date.now() - (lastDays*ONE_DAY_MS)).toISOString().split('T')[0])
		setEndDate(new Date().toISOString().split('T')[0])
		setHoveredDate('')
	}

	const getTotalSelectedDays = (startDate?:string, endDate?:string) => {
		if (startDate && endDate && endDate > startDate) {
			const start = new Date(startDate)
			const end = new Date(endDate)
			if (props.onRangeSelected)
				props.onRangeSelected([start, end])
			const days = Math.round((end.getTime() - start.getTime())/ONE_DAY_MS)
			return days <= 1 ? `${days} day` : `${days} days`
		} else
			return `- days`
	}

	return (<CalendarDiv>
		<div className="calendar">
			<div className="dates-selector">
				<div className="arrow left" onClick={addMonth(-1)}>
					<KeyboardArrowLeftIcon/>
				</div>
				<div className="date-title noselect">
					<span>{MONTH_NAMES[month-1]}</span>
					<span style={{ marginLeft:'8px' }}>{year}</span>
				</div>
				{!isCurrentMonth(year, month) ? <div className="arrow right" onClick={addMonth(1)}>
					<KeyboardArrowRightIcon/>
				</div> : null }
			</div>
			<div className="month-days">
				<div className="week-days">
					<div className="days-headers">
						<div className="day-cell">Su</div>
						<div className="day-cell">Mo</div>
						<div className="day-cell">Tu</div>
						<div className="day-cell">We</div>
						<div className="day-cell">Th</div>
						<div className="day-cell">Fr</div>
						<div className="day-cell">Sa</div>
					</div>
					{getWeekDays(month, year).map((weekDays, j) => {
						return (<div key={j} className="week-days-row">
							{addStyle(weekDays, startDate, endDate, hoveredDate).map((d:any, i:any) => {
								return (<div 
									key={i} 
									className={d.cellClass} 
									onClick={() => !d?.isFuture ? selectDate(d?.date) : null} 
									onMouseEnter={() => !d?.isFuture ? onHoverDay(d) : null}>
									<span style={{ zIndex:2 }}>{d.day||''}</span>
									<div className={d.cellBackgroundClass}></div>
								</div>)
							})}
						</div>)
					})}
				</div>
			</div>
		</div>
		<div className="calendar-predefined">
			<div className="header">Predefined dates</div>
			<div onClick={selectLastDays(7)}>Last 7 days</div>
			<div onClick={selectLastDays(30)}>Last 30 days</div>
			<div onClick={selectLastDays(60)}>Last 60 days</div>
		</div>
		<div className="calendar-footer">
			<div className="calendar-footer-container">
				<div>
					<span style={{ color:'#00000080' }}>Selected:</span>
					<span style={{ marginLeft:'8px', fontWeight:'bold' }}>{getTotalSelectedDays(startDate, endDate)}</span>
				</div>
			</div>
		</div>
	</CalendarDiv>)
}


export default Calendar



