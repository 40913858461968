import { gql } from '@apollo/client'

export const GET_USER = `
users(me:true){
	data{
		id
		first_name
		last_name
		email
		default_tower_id
		metadata
		phone {
			code
			number
		}
	}
}`

export const GET_USER_GQL = gql`
query get_user {
	${GET_USER}
}`