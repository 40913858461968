import { useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { IonContent, IonPage, useIonModal, useIonToast } from '@ionic/react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import LogoutIcon from '@mui/icons-material/Logout'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import Collapse from '@mui/material/Collapse'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { grey } from '@mui/material/colors'
// import IconButton, { IconButtonProps } from '@mui/material/IconButton'
// import { styled as mdStyled } from '@mui/material/styles'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { logOut, getSessionUser } from '../auth'
import FormFeedback from '../components/FormFeedback'
import { TitleHeader } from '../components/Header'
// import { LOGIN_PATH, NOTIFICATION_TYPES_PATH, PROFILE_PATH, SPRAY_LOG_PATH } from '../path'
import { LOGIN_PATH, PROFILE_PATH, SPRAY_LOG_PATH } from '../path'
import pack from '../../package.json'
import Toast from '../utils/Toast'
import { useLogFeatureInfo } from '../utils/session'
import { GET_USER_GQL } from '../data/user'
import { isInstallable } from '../utils/browser'
import InstallAppMessage from '../components/InstallAppMessage'

const TERMS_PATH = '/assets/legal/20221212_terms.pdf'
const PRIVACY_POLICY_PATH = 'https://www.goannaag.com.au/privacy-policy'
const USER_MANUAL = '/assets/manual/20230215_wand_manual.pdf'
const CONTAINER_PAD = '7px'
const GUTTER = '26px'
const HEADER_ICON_CELL_WIDTH = '70px'

export const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const SectionItemDiv = styled.div.attrs(({ info, lastitem, disabled }:{info?:boolean, lastitem?:boolean, disabled?:boolean}) => ({ info, lastitem, disabled }))`
	padding-top: ${CONTAINER_PAD};
	padding-left: ${GUTTER};
	padding-right: ${GUTTER};
	padding-bottom: ${(props:any) => props.lastitem ? '20px' : CONTAINER_PAD};
	display: grid;
	grid-template-areas: 
		'title    icon'
		'subtitle icon';
	grid-template-rows: 24px 24px;
	grid-template-columns: 1fr 60px;

	& .title {
		grid-area: title;
		${(props:any) => !props.disabled ? '' : `color: ${grey[600]};`}
	}

	& .subtitle {
		grid-area: subtitle;
	}

	& .icon {
		grid-area: icon;
		display: flex;
		align-items: center;
		justify-content: center;

		${(props:any) => !props.disabled ? '' : `
		& svg {
			color: ${grey[400]};
		}`}
	}

	${(props:any) => props.disabled || props.info ? '' : `
	cursor: pointer;
	&:hover {
		background-color: ${grey[100]};
	}`
	}
`

export const SectionDivider = ({ ...props }) => {
	const { top } = props
	return (
		<Box sx={{ margin: `${top || '0px'} -${CONTAINER_PAD} 0px -${CONTAINER_PAD}` }}>
			<Divider/>
		</Box>
	)
}

const SettingsHeaderContainerGrid = `
	display: grid;
	grid-template-columns: ${HEADER_ICON_CELL_WIDTH} 1fr 60px;
`
const SettingsHeaderContainer = styled.div`
	padding-bottom: ${CONTAINER_PAD};
	padding-left: ${GUTTER};
	padding-right: ${GUTTER};
	padding-top: 30px;
	grid-template-rows: 80px 1fr;
	${SettingsHeaderContainerGrid}
`

const OrganizationsMenuGrid = styled.div.attrs(({ key, clickable }:any) => ({ key:key||'', clickable:clickable||false }))`
	cursor: ${({ clickable }:any) => clickable ? 'pointer' : 'default'};
	grid-template-rows: 50px 1fr;
	${SettingsHeaderContainerGrid}
`

// interface ExpandMoreProps extends IconButtonProps {
// 	expand: boolean
// }

// const ExpandMore = mdStyled((props: ExpandMoreProps) => {
// 	const { expand, ...other } = props
// 	return <IconButton {...other} />
// })(({ theme, expand }) => ({
// 	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
// 	transition: theme.transitions.create('transform', {
// 		duration: theme.transitions.duration.shortest,
// 	}),
// 	border: `1px solid ${theme.palette.divider}`,
// 	backgroundColor: '#00000008'
// }))

export const SectionTitle = ({ ...props }) => {
	return (<Typography variant="h6" component="h2" sx={{ paddingLeft:GUTTER, paddingRight:GUTTER, paddingBottom:'15px', paddingTop:'10px' }}>{props.children}</Typography>)
}

export const SectionItem = ({ ...props }) => {
	const onClick = props.onClick || (() => null)

	return (
		<SectionItemDiv onClick={onClick} info={props.info} disabled={props.disabled} lastitem={props.lastitem} >
			<Typography variant="body1" sx={{ fontWeight:500 }} className="title">{props.children}</Typography>
			{props.description && 
				<Typography variant="caption" color="text.secondary" className="subtitle">{props.description}</Typography>
			}
			{props.icon && 
				<Box className="icon">{props.icon}</Box>}
			{props.note && 
				<Typography variant="caption" color="text.secondary" sx={{ opacity:0.9, fontStyle:'italic', marginTop:'8px' }}>
					{props.note}
				</Typography>}
		</SectionItemDiv>
	)
}

const SettingsHeader = ({ ...props }) => {
	const { orgs, fullname } = props
	// const [expanded, setExpanded] = useState(false)
	const expanded = false

	// const handleExpandClick = () => {
	// 	setExpanded(!expanded)
	// }

	return (
		<SettingsHeaderContainer>
			<Box sx={{ display:'flex', alignItems:'flex-start', justifyContent:'flex-start' }}>
				<AccountCircleIcon sx={{ fontSize:'54px' }}/>
			</Box>
			<Box>
				<Typography variant="h5" component="h2" sx={{ fontWeight:'bold' }}>{fullname}</Typography>
				{/*For Phase II*/}
				{/*<Typography variant="body2" color="text.secondary">Private account</Typography>*/}
			</Box>
			{/*For Phase II*/}
			{/*<Box sx={{ display:'flex', alignItems:'flex-start', justifyContent:'center' }}>
				<ExpandMore
					expand={expanded}
					onClick={handleExpandClick}
					aria-expanded={expanded}
					aria-label="show more"
				>
					<ExpandMoreIcon/>
				</ExpandMore>
			</Box>*/}
			<OrganizationsMenu expanded={expanded} orgs={orgs}/>
		</SettingsHeaderContainer>
	)
}

const OrganizationsMenu = ({ ...props }) => {
	const { orgs, expanded } = props||{}
	const iconStyle = { opacity:0.6 }
	const items = (orgs||[]).map((o:any) => ({ 
		...o, 
		icon:<CorporateFareIcon sx={iconStyle}/>, 
		menu:<MoreVertIcon fontSize="small"/> 
	}))
	if (items.length)
		items.push({ 
			name: 'Use without an organization',
			icon:<DomainDisabledIcon sx={iconStyle}/>,
			menu: <ArrowForwardIosIcon fontSize="small"/>,
			clickable: true
		})
	items.push({ 
		name: 'Add a new organization',
		icon:<DomainAddIcon sx={iconStyle}/>,
		menu: <ArrowForwardIosIcon fontSize="small"/>,
		clickable: true
	})

	return (
		<Collapse in={expanded} timeout="auto" sx={{ gridColumnStart:1, gridColumnEnd:4 }}>
			<Box >
				<Divider sx={{ marginLeft: HEADER_ICON_CELL_WIDTH, marginRight: `-${GUTTER}` }}/>
				{items.map((item:any, idx:number) => {
					return (
					<OrganizationsMenuGrid key={idx} clickable={item.clickable}>
						<Box sx={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
							{item.icon}
						</Box>
						<Box sx={{ display:'flex', alignItems:'center', justifyContent:'flex-start' }}>
							{item.name}
						</Box>
						{item.menu && <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
							{item.menu}
						</Box>}
					</OrganizationsMenuGrid>
					)
				})}
			</Box>
		</Collapse>
	)
}

const Settings = ({ ...props }) => {
	const head = <Helmet>
		<title>Wand - Settings</title>
	</Helmet>

	const { yes:canInstalled, alreadyInstalled } = isInstallable(props.deferredPrompt)
	const disableAddToHomescreen = !canInstalled && alreadyInstalled
	const userOp = useQuery(GET_USER_GQL)
	const history = useHistory()
	const location = useLocation<any>()

	const [email, setEmail] = useState('')
	const [fullname, setFullname] = useState('')
	const logFeat = useLogFeatureInfo()

	const orgs = [{ name:'My company' }]

	const toast = new Toast(...useIonToast(), { duration:5000 })

	const [presentFeedback, dismissFeedback] = useIonModal(FormFeedback, {
		onCancel: () => {
			dismissFeedback()
		},
		onConfirm: () => {
			toast.show('Feedback sent')
			dismissFeedback()
		}
	})

	const [showInstallAppMessage, closeInstallAppMessage] = useIonModal(InstallAppMessage, {
		onCancel: () => {
			closeInstallAppMessage()
		}
	})

	useEffect(() => {
		if (!userOp.loading) {
			const u = (userOp.data?.users?.data||[])[0]
			if (u) {
				const { first_name, last_name, email } = u
				if (first_name || last_name) 
					setFullname([first_name,last_name].filter(x => x).join(' '))
				else
					setFullname(email)
				setEmail(email)
			}
		}
	}, [location, userOp]) // Listening to 'location' guarantees the this code is executed each time the navigation changes via the history.push API

	// Manages redirection to login page if user is not authenticated
	const [redirect, setRedirect] = useState<boolean | null>(null)
	useEffect(() => getSessionUser().then(async (resp: any) => setRedirect(resp && resp[0] ? true : false), []))
	if (redirect === null)
		return null

	const signOut = async () => {
		await logOut()
		window.location.href = LOGIN_PATH
	}

	const goToPrivacyPolicy = () => {
		window.open(PRIVACY_POLICY_PATH, '_blank')
		// history.push(PRIVACY_POLICY_PATH, { origin:'settings' })
	}

	const goToTerms = () => {
		window.open(TERMS_PATH, '_blank')
		// history.push(TERMS_PATH, { origin:'settings' })
	}

	const goToManual = () => {
		window.open(USER_MANUAL, '_blank')
		// history.push(TERMS_PATH, { origin:'settings' })
	}

	const goToSprayLogs = () => {
		logFeat({ title:'list_spray_log' })
		history.push(SPRAY_LOG_PATH)
	}

	const addToHomeScreen = async () => {
		if (canInstalled) {
			// Show the install prompt
			props.deferredPrompt.prompt()
			const { outcome } = await props.deferredPrompt.userChoice
			if (outcome != 'dismissed')
				props.deferredPrompt = null
		} else {
			showInstallAppMessage()
		}
	}

	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<div>
			<IonPage>
				{head}
				<TitleHeader title="Settings" onBack={() => history.push('/')} />
				<IonContent fullscreen>
					<SettingsHeader orgs={orgs} fullname={fullname}/>
					<SettingsContainer>
						<SectionDivider top="0px"/>

						<SectionTitle>Profile</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="Edit your profile details" 
							onClick={() => history.push(PROFILE_PATH)} 
							clickable
							icon={<ArrowForwardIosIcon color="disabled"/>}>
							Profile details
						</SectionItem>

						<SectionDivider/>

						{/*This set of settings is for Phase II*/}
						{/*<SectionTitle>Account</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="View your plan"
							note="NOTE: This plan is used for your personal account. If you manage organizations, each of them use a different plan."
							onClick={editProfile} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Free
						</SectionItem>

						<SectionDivider/>

						<SectionTitle>Team</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="Manage your organization's members"
							note="WARNING: Team members can only be added in organizations. Clicking on this tile will prompt to create an organization first (free)."
							onClick={editProfile} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Members (0)
						</SectionItem>

						<SectionDivider/>

						<SectionTitle>Payments</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="Manage your payment methods"
							onClick={editProfile} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Payment methods
						</SectionItem>

						<SectionDivider/>*/}

						{/*<SectionTitle>Notifications</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="Choose which notifications to receive"
							onClick={() => history.push(NOTIFICATION_TYPES_PATH)} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Notifications
						</SectionItem>

						<SectionDivider/>*/}

						<SectionTitle>Logs</SectionTitle>
						<SectionItem 	
							lastitem={true}
							description="Search the spray log"
							onClick={goToSprayLogs} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Spray log
						</SectionItem>

						<SectionDivider/>

						<SectionTitle>About</SectionTitle>
						<SectionItem 
							description="Read more in our user manual"
							onClick={goToManual} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Help
						</SectionItem>
						<SectionItem 
							description="Send us feedback"
							onClick={presentFeedback} 
							clickable>
							Feedback
						</SectionItem>
						<SectionItem 
							description={pack.version}
							info={true}>
							Version
						</SectionItem>
						<SectionItem 
							description="All the things you need to know"
							onClick={goToTerms} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Terms and conditions
						</SectionItem>
						<SectionItem 
							description="Important for both of us"
							onClick={goToPrivacyPolicy} 
							clickable
							lastitem={true}
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Privacy policy
						</SectionItem>

						<SectionDivider/>

						<SectionTitle>Others</SectionTitle>
						<SectionItem 
							disabled={disableAddToHomescreen}
							description={`${disableAddToHomescreen ? 'This app is already installed' : 'Install this app'} on your device`}
							onClick={disableAddToHomescreen ? () => null : addToHomeScreen} 
							clickable
							icon={<ControlPointIcon color="primary"/>}>
							Add to homescreen
						</SectionItem>
						<SectionItem 
							lastitem={true}
							description={`You are logged in${email ? ` as ${email}` : ''}`}
							onClick={signOut} 
							clickable
							icon={<LogoutIcon color="primary"/>}>
							Log out
						</SectionItem>
						<Box sx={{ width:'100%', height:'80px' }}></Box>
					</SettingsContainer>
				</IonContent>
			</IonPage>
		</div>
	)
}

export default Settings
