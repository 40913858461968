import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Redirect, Route } from 'react-router-dom'
import {
	IonApp,
	IonRouterOutlet,
	setupIonicReact
} from '@ionic/react'
import { useState } from 'react'
import { IonReactRouter } from '@ionic/react-router'
import Weather from './pages/Weather'
import { LoginSignup, Modes } from './pages/LoginSignup'
import Settings from './pages/Settings'
import Notification from './pages/Notification'
import NotificationSetting from './pages/NotificationSetting'
import Profile from './pages/Profile'
import SprayLog from './pages/SprayLog'
import NotificationMessage from './pages/NotificationMessage'
import Terms from './pages/Terms'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Search from './pages/Search'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

import { getAccessToken } from './auth'

import { 
	CONFIRM_CODE_PATH, 
	FORGOT_PWD_PATH, 
	LOGIN_PATH, 
	NOTIFICATION_PATH, 
	NOTIFICATION_MESSAGE_PATH,
	NOTIFICATION_TYPES_PATH, 
	PROFILE_PATH,
	RESET_PWD_PATH, 
	SEARCH_PATH,
	SETTINGS_PATH, 
	SIGNUP_PATH,
	SPRAY_LOG_PATH,
	TERMS_PATH,
	PRIVACY_POLICY_PATH
} from './path'

setupIonicReact()

if (!process.env.REACT_APP_GRAPHQL_ENDPOINT)
	throw new Error(`Missing required environment variable 'GRAPHQL_ENDPOINT'`)

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
})

const authLink = setContext((_, { headers }) => {
	return getAccessToken().then((resp:any) => {
		const [errors, token] = resp
		if (errors)
			throw new Error(errors.map((e:any) => e.message).join('\n'))
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			}
		}
	})
})

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache()
})

const App: React.FC = () => {
	const [deferredPrompt, setDeferredPrompt] = useState<any>(null)
	// Initialize deferredPrompt for use later to show browser install prompt.

	window.addEventListener('beforeinstallprompt', (e) => {
		// Prevent the mini-infobar from appearing on mobile
		e.preventDefault()
		// Stash the event so it can be triggered later.
		setDeferredPrompt(e)
		// Update UI notify the user they can install the PWA
		// showInstallPromotion();
		// Optionally, send analytics event that PWA install promo was shown.
		console.log(`'beforeinstallprompt' event was fired`)
	})
	return <ApolloProvider client={client}>
		<IonApp>
			<IonReactRouter>
				<IonRouterOutlet>
					<Route exact path={LOGIN_PATH}>
						<LoginSignup deferredPrompt={deferredPrompt}/>
					</Route>
					<Route exact path={SIGNUP_PATH}>
						<LoginSignup mode={Modes.signup} deferredPrompt={deferredPrompt}/>
					</Route>
					<Route exact path={FORGOT_PWD_PATH}>
						<LoginSignup mode={Modes.forgotpwd} deferredPrompt={deferredPrompt}/>
					</Route>
					<Route exact path={RESET_PWD_PATH}>
						<LoginSignup mode={Modes.resetpwd} deferredPrompt={deferredPrompt}/>
					</Route>
					<Route exact path={CONFIRM_CODE_PATH}>
						<LoginSignup mode={Modes.codeconfirm} deferredPrompt={deferredPrompt}/>
					</Route>
					<Route exact path={SETTINGS_PATH}>
						<Settings deferredPrompt={deferredPrompt} />
					</Route>
					<Route exact path={NOTIFICATION_PATH}>
						<Notification />
					</Route>
					<Route path={NOTIFICATION_MESSAGE_PATH}>
						<NotificationMessage />
					</Route>
					<Route exact path={PROFILE_PATH}>
						<Profile />
					</Route>
					<Route exact path={NOTIFICATION_TYPES_PATH}>
						<NotificationSetting />
					</Route> 
					<Route exact path={SPRAY_LOG_PATH}>
						<SprayLog />
					</Route>
					<Route exact path={TERMS_PATH}>
						<Terms />
					</Route>
					<Route exact path={PRIVACY_POLICY_PATH}>
						<PrivacyPolicy />
					</Route>
					<Route exact path={SEARCH_PATH}>
						<Search />
					</Route>
					<Route exact path="/">
						<Weather />
					</Route>
					<Redirect to="/" />
				</IonRouterOutlet>
			</IonReactRouter>
		</IonApp>
	</ApolloProvider>
}

export default App
